import React, { Component } from 'react'
// import { PropTypes } from 'prop-types'
import { inject, observer } from 'mobx-react'
import logger from '../utils/logger'
import uuidV4 from 'uuid/v4'
import Spinner from './common/Spinner'
import CellarDetails from './CellarDetails'

const ConnectionManager = require('../ConnectionManager')

class Cellar extends Component {
  constructor (props) {
    super(props)

    this.state = {
      queryState: 'loading',
      cellars: [],
      activeCellarId: null,
      cellarName: ''
    }

    this.cellars = []
  }

  componentWillUnmount () {
    if (this.connectionManager) {
      this.connectionManager.closeSocket()
    }
  }

  componentDidMount () {
    this.connectionManager = ConnectionManager('/cellars', this.props.store)

    this.connectionManager.sendRequest({
      method: 'get',
      keepAlive: true,
      cb: (err, message) => {
        if (err) {
          logger.error('ERROR', err)
        } else {
          switch (message.type) {
            case 'state':
              if (message.data === 'ready') {
                this.setState({
                  queryState: 'ready',
                  cellars: this.cellars,
                  activeCellarId: (this.cellars && this.cellars[0] ? this.cellars[0].id : null)
                })
                this.cellars = null
              }
              break
            case 'data':
              switch (this.state.queryState) {
                case 'loading':
                  this.cellars.push(message.data)
                  break
                case 'ready': {
                  const c = this.state.cellars.filter((cellar) => cellar.id !== message.data.id)
                  c.push(message.data)
                  this.setState({
                    cellars: c
                  })
                  break
                }
                default:
                  throw new Error('Unexpected state')
              }
              break
            case 'dataDeleted':
              this.setState({
                cellars: this.state.cellars.filter((cellar) => cellar.id !== message.data)
              })
              break
            default:
              throw new Error('Unexpected case ', message.type)
          }
        }
      }
    })
  }

  saveCellar = (cellar) => {
    this.connectionManager = ConnectionManager('/cellars', this.props.store)

    this.connectionManager.sendRequest({
      method: 'save',
      params: {
        data: {
          cellar
        }
      },
      cb: (err, message) => {
        if (err) {
          logger.error('ERROR', err)
          this.setState({
            queryState: 'error'
          })
        }
      }
    })
  }

  handleClick = (tabId) => {
    if (tabId === 'addCellar') {
      const id = uuidV4()
      this.saveCellar({
        id,
        name: 'New location'
      })
      this.setState({
        activeCellarId: id
      })
    } else {
      this.setState({
        activeCellarId: tabId
      })
    }
  }

  handleSave = () => {
    this.connectionManager = ConnectionManager('/cellars', this.props.store)
    const id = uuidV4()

    this.connectionManager.sendRequest({
      method: 'save',
      params: {
        data: {
          cellar: {
            id,
            name: this.state.cellarName
          }
        }
      },
      cb: (err, message) => {
        if (err) {
          logger.error('ERROR', err)
          this.setState({
            queryState: 'error'
          })
        }
        this.closeButton.click()
        this.setState({
          activeCellarId: id,
          cellarName: ''
        })
      }
    })
  }

  handleNameChange = (e) => {
    this.setState({
      cellarName: e.target.value
    })
  }

  getCellar = () => {
    const cellar = this.state.cellars.filter(cellar => cellar.id === this.state.activeCellarId)
    if (cellar && cellar[0]) {
      return cellar.map((c) => {
        return (
          <CellarDetails key={c.id} saveCellar={this.saveCellar} cellar={c} />
        )
      })
    } else {
      return null
    }
  }

  render () {
    if (this.state.queryState === 'loading') {
      return <Spinner text='Loading...' className='spaceAfter ' />
    }

    return (
      <div className='container' style={{ marginTop: 10 }}>
        <ul className='nav nav-tabs' role='tablist' style={{ marginBottom: 20 }}>
          {this.state.cellars.sort((a, b) => (a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0).map(cellar => {
            return (
              <li key={cellar.id} role='presentation' className={this.state.activeCellarId === cellar.id ? 'active' : null}>
                <a href='#' onClick={this.handleClick.bind(this, cellar.id)}>{cellar.name}</a>
              </li>
            )
          })}
          <li role='presentation'>
            <a href='#' className='btn' data-toggle='modal' data-target='#addCellar'>+ Add Cellar</a>
          </li>

          <div id='addCellar' className='modal fade' tabIndex='-1' role='dialog' aria-labelledby='addCellar'>
            <div className='modal-dialog modal-sm' role='document'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4>New cellar</h4>
                </div>

                <div className='modal-body'>
                  <label htmlFor='locationName'>Name: </label>
                  <input style={{ marginLeft: 10 }} type='text' value={this.state.cellarName} onChange={this.handleNameChange} />
                </div>

                <div className='modal-footer' style={{ marginTop: 10 }}>
                  <div className='row' style={{ marginLeft: 10, marginRight: 10 }}>
                    <div className='btn-group'>
                      <button ref={element => (this.closeButton = element)} style={{ marginLeft: 10 }} type='button' className='btn btn-default pull-right' data-dismiss='modal'>Cancel</button>
                      <button type='button' className='btn btn-primary pull-right' onClick={this.handleSave}>Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ul>

        <div className='container' style={{ marginTop: 40 }}>
          <div className='row'>
            <form className='form-inline'>
              <input className='form-control' style={{ minWidth: 400 }} type='text' placeholder='Search for orders containing dates, wines, prices...' value={this.state.filterText} onChange={this.searchClick} title='Search by wine, date, amount' />
            </form>
          </div>

          <div className='row'>
            {this.getCellar()}
          </div>
        </div>
      </div>
    )
  }
}

export default inject('store')(observer(Cellar))
