import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { PropTypes } from 'prop-types'
import logger from '../utils/logger'
import ConnectionManager from '../ConnectionManager'

class ConfirmForm extends Component {
  constructor (props) {
    super(props)

    this.state = {
      stage: 'submitting',
      userCode: null,
      password1: '',
      password2: ''
    }
  }

  componentDidMount () {
    ConnectionManager('/users', this.props.store).sendRequestPromise({
      method: 'confirm',
      params: {
        data: {
          token: this.props.match.params.token
        }
      }
    }).then((response) => {
      if (response.type === 'data' && response.data.userCode) {
        this.setState({
          userCode: response.data.userCode,
          stage: 'success'
        })
      }
    }).catch((error) => {
      logger.error(error)
      this.setState({
        stage: error.data
      })
    })
  }

  handleChange = (e) => {
    const { name, value } = e.currentTarget
    const nextState = {}

    nextState[name] = value

    this.setState(nextState)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    ConnectionManager('/users', this.props.store).sendRequestPromise({
      method: 'setPassword',
      params: {
        data: {
          userCode: this.state.userCode,
          password: this.state.password1
        }
      }
    })
      .then((response) => {
        console.log('response', response)
        if (response.type === 'data' && response.data === 'Success') {
          this.props.history.replace('/logout')
        }
      })
      .catch((error) => {
        logger.error(error)
        this.setState({
          stage: error.data
        })
      })

    return false
  }

  render () {
    if (this.state.stage === 'submitting') {
      return <div>Retrieving account details, please wait...</div>
    } else if (this.state.stage === 'success') {
      return (
        <div className='container'>
          <h3 className='row'>Thank you for your interest in Wheeler Wines.  To activate your account, please choose a password:</h3>
          <div className='col-sm-6 col-sm-offset-3'>
            <p className='text-center'>Use the form below to enter your password. Your password cannot be the same as your username.</p>
            <form>
              <div className='form-group'>
                <label htmlFor='password1'>Password</label>
                <input type='password' className='form-control' name='password1' placeholder='Password' onChange={this.handleChange} />
                <div className='col-6'>
                  <p>{this.state.password1.length < 6 ? <span role='img' aria-label='password must be 6 characters or more' style={{ fontSize: '80%' }}>❌</span> : <span aria-label='good password length' role='img'>👍</span>} 6 characters or more</p>
                </div>
                <div className='col-6'>
                  <p>{this.state.userCode === this.state.password1.toUpperCase() ? <span role='img' aria-label='password must be different from user name' style={{ fontSize: '80%' }}>❌</span> : <span aria-label='good password' role='img'>👍</span>} Different from username</p>
                </div>
              </div>
              <div className='form-group'>
                <label htmlFor='password2'>Confirm password</label>
                <input type='password' className='form-control' name='password2' placeholder='Repeat password' onChange={this.handleChange} />
                <div className='col-6'>
                  <p>{this.state.password1 !== this.state.password2 ? <span aria-label='passwords do not match' role='img' style={{ fontSize: '80%' }}>❌</span> : <span aria-label='passwords match' role='img'>👍</span>} Passwords match</p>
                </div>
              </div>

              <button type='submit' className='btn btn-primary' disabled={this.state.userCode === this.state.password1.toUpperCase() || this.state.password1.length < 6 || this.state.password1 !== this.state.password2} onClick={this.handleSubmit}>Submit</button>
            </form>
          </div>
        </div>
      )
    } else {
      return <div>Error: {this.state.stage}</div>
    }
  }
}

ConfirmForm.propTypes = {
  history: PropTypes.object.isRequired,
  // injected by react-router
  match: PropTypes.object.isRequired
}

export default inject('store')(observer(ConfirmForm))
