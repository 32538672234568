import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

class Logout extends Component {
  UNSAFE_componentWillMount () {
    this.props.store.logout()
    this.props.history.replace('/')
  }

  render () {
    return (
      <div>Logout</div>
    )
  }
}

export default inject('store')(observer(Logout))
