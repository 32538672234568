import React from 'react'

const NoPropLineItem = (props) => {
  const { role, href } = props
  const p = { role, href }
  return (
    <li {...p}>{props.children}</li>
  )
}

export default NoPropLineItem
