import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import formatPrice from '../utils/formatPrice'

class BuyPanel extends Component {
  buy = (count) => {
    return (e) => {
      e.preventDefault()
      e.stopPropagation()

      // Is this item already in the cart?
      let item = this.props.cart.find(item => item.id === this.props.wine.id)
      if (!item) {
        item = {
          id: this.props.wine.id,
          name: this.props.wine.name,
          vintage: this.props.wine.vintage,
          type: this.props.wine.type,
          price: this.props.wine.price,
          size: this.props.wine.size,
          qty: count
        }
        this.props.cart.push(item)
      } else {
        item.qty += count
      }

      return false
    }
  }

  render () {
    const { wine, user, token } = this.props

    if (user && token && wine.price) {
      return (
        <div>
          <a href='#' role='button' className='btn btn-primary' style={{ marginRight: 10 }} onClick={this.buy(1)}>{`Add 1: ${formatPrice(wine.currency, wine.price)}`}</a>
          <a href='#' role='button' className='btn btn-primary' onClick={this.buy(6)}>{`Add 6: ${formatPrice(wine.currency, wine.price * 6)}`}</a>
          <p className='badge badge-default' style={{ float: 'right', marginTop: 8 }}>
            {this.props.numberInCart === 0 ? null : `${this.props.numberInCart} in basket`}
          </p>
        </div>
      )
    } else {
      return (
        <p><i>Login to see price</i></p>
      )
    }
  }
}

BuyPanel.propTypes = {
  wine: PropTypes.object.isRequired,
  user: PropTypes.object,
  token: PropTypes.string,
  numberInCart: PropTypes.number.isRequired,
  cart: PropTypes.object.isRequired
}

export default BuyPanel
