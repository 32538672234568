import logger from './utils/logger'
const socketio = require('socket.io-client')

const managers = {}

function ConnectionManager (url, store) {
  if (url === '/users') {
    console.log('/users')
  }

  if (managers[url]) {
    return managers[url]
  }

  try {
    let socket = socketio(url)

    const sessions = {}

    socket.on('connect', () => {
      logger.debug(`connected to ${url}`)
    })

    socket.on('error', (e) => {
      logger.error('error', e)
      store.setConnectionStatus({ status: store.STATUS_BAD, messages: [e] })
    })

    socket.on('disconnect', () => {
      logger.debug(`disconnected from ${url}`)
    })

    socket.on('response', (response) => {
      // Whenever we receive a response update our messageCounter. Another 'process' is responsible for clearing it
      store.messageCounter++

      if (response.extras) {
        const { _frameId } = response.extras
        if (_frameId) {
          const session = sessions[_frameId]

          if (session) {
            if (session.keepAlive === false) {
              delete sessions[_frameId]
            }

            // Kill the timeout
            if (session.timeout) {
              clearTimeout(session.timeout)
            }

            // Send response to the relevant callback
            delete response.extras._frameId
            if (session.cb) {
              if (response.type === 'error') {
                if (response.data === 'InvalidToken') {
                  store.logout('Token has expired or is not valid')
                }

                store.setConnectionStatus({ status: store.STATUS_BAD, messages: [response.data] })

                session.cb(response)
              } else {
                session.cb(null, response)
              }
            }
          }
        }
      }
    })

    const closeSocket = () => {
      if (socket) {
        socket.disconnect()
        socket = null
        delete managers[url]
      }
    }

    const sendRequest = ({ method, params, extras = {}, cb, timeoutMillis = 5000, keepAlive = false }) => {
      if (typeof cb !== 'function') {
        throw new Error('You must specify a callback function')
      }

      extras._frameId = new Date().getTime() + '|' + Math.random()

      const timeout = keepAlive
        ? null
        : setTimeout(() => {
          delete sessions[extras._frameId]
          store.setConnectionStatus({ status: store.STATUS_BAD, messages: [`Timeout for ${method}`] })
          cb(new Error('Timeout'))
        }, timeoutMillis)

      sessions[extras._frameId] = {
        cb,
        timeout,
        keepAlive
      }

      socket.emit('request', {
        method,
        params,
        extras,
        token: (store.user && store.token ? store.token : null)
      })
    }

    const sendRequestPromise = ({ method, params, extras, timeoutMillis }) => {
      return new Promise((resolve, reject) => {
        sendRequest({
          method,
          params,
          extras,
          timeoutMillis,
          cb: (err, data) => {
            if (err) {
              store.setConnectionStatus({ status: store.STATUS_BAD, messages: [err] })
              reject(err)
            } else {
              resolve(data)
            }
          }
        })
      })
    }

    managers[url] = {
      sendRequest,
      sendRequestPromise,
      closeSocket
    }
  } catch (err) {
    managers[url] = {
      sendRequest: () => {},
      sendRequestPromise: () => { Promise.resolve() },
      closeSocket: () => {}
    }
  }

  return managers[url]
}

export default ConnectionManager
