import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { inject, observer } from 'mobx-react'
import { select } from 'd3-selection'
import { line, curveBasis } from 'd3-shape'
import { scaleLinear } from 'd3-scale'
import { easeLinear } from 'd3-ease'
import { transition } from 'd3-transition'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const connectionStatusStyles = {
  good: {
    stroke: 'rgb(0, 255, 0)',
    fill: 'rgb(0, 200, 0)'
  },
  warning: {
    stroke: 'rgb(255, 165, 0)',
    fill: 'rgb(200, 110, 0)'
  },
  bad: {
    stroke: 'rgb(255, 0, 0)',
    fill: 'rgb(200, 0, 0)'
  }
}

class Heartbeat extends Component {
  componentDidMount () {
    this.generateChart()
  }

  componentWillUnmount () {
    // Stop updating, next call to update() will do nothing and the d3 stuff will stop
    this.stop = true
  }

  generateChart = () => {
    // create an SVG element inside the #graph div that fills 100% of the div
    this.chart = select('#heartbeat')
      .append('svg:svg')
      .attr('width', '100%')
      .attr('height', '100%')

    const width = +this.props.width
    const height = +this.props.height
    const maxCount = 5 // Increase this number if we are regularly getting more than this per update tick (props.updateTime) i.e. the line is always jammed at the top
    const xPoints = 40 // This number divided by props.updateTime will be time slice you are showing e.g. 40 / 500ms = 20 seconds worth of data

    // Chart data
    this.data = []

    // Set initial values to 0
    for (let i = 0; i < xPoints; i++) {
      this.data.push(0)
    }

    this.x = scaleLinear()
      .domain([0, xPoints - 2]) // -2 for the ones off screen
      .range([-5, width + 5]) // Start off screen so we can animate it sliding in without part of the line magically appearing

    this.y = scaleLinear()
      .domain([0, maxCount])
      .range([height, 0])

    this.line = line()
      .x((d, i) => {
        return this.x(i)
      })
      .y((d) => {
        // Draw the line stuck at the top rather than off the div
        const h = this.y(d) - 1
        return h < 1 ? 1 : h
      })
      .curve(curveBasis)
      // .interpolate('basis') // linear/basis, linear is pointy, basis is curved

    // Draw the line with the initial data
    this.chart.append('path').attr('d', this.line(this.data))

    this.update()
  }

  update = () => {
    if (!this.stop) {
      // Remove first item and add the current messageCounter to the end
      // and then reset the messageCounter to 0
      this.data.shift()
      this.data.push(this.props.store.messageCounter)
      this.props.store.messageCounter = 0

      // Redraw with the new data
      this.redrawChart(this.data)
    }
  }

  redrawChart = (data) => {
    transition(
      this.chart.selectAll('path')
        .data([data]) // set the new data
        .attr('transform', 'translate(' + this.x(1) + ')') // Transform the line by 1 to move it to the right
        .attr('d', this.line) // Set new data
        .transition() // Start transition to move new data into voew
        // .ease('linear')
        .duration(this.props.updateTime || 250)
        .ease(easeLinear)
        .attr('transform', 'translate(' + this.x(0) + ')') // We want to end up at x(0) i.e. where it should be plotted with no animation
        .on('end', () => {
          // Finished redrawing, call update to do the next 'tick'
          this.update(true)
        })
    )
  }

  render () {
    const { connectionStatus, roundTripTimes, averageRTT } = this.props.store

    let connectionStatusStyle = connectionStatusStyles[connectionStatus.status]
    connectionStatusStyle = connectionStatusStyle || connectionStatusStyles.bad

    return (
      <div>
        <div
          id='heartbeat'
          style={{ width: this.props.width, height: this.props.height, float: 'left' }}
        />
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip id='tooltip'>
              {connectionStatus.messages.map((message, i) => {
                return <div key={i}>{message}<br /></div>
              })}
              <br />Average RTT - {averageRTT}ms
              <br />Last 10 RTT
              {
                roundTripTimes.map((rtt, i) => {
                  return <div key={`${rtt}-${i}`}>{`${rtt}ms`}<br /></div>
                })
              }
            </Tooltip>
          }
        >
          <svg style={{ width: '20px', height: '20px' }}>
            <circle cx={10} cy={10} r={3} fill={connectionStatusStyle.fill} stroke={connectionStatusStyle.stroke} strokeWidth={1} />
          </svg>
        </OverlayTrigger>
      </div>
    )
  }
}

Heartbeat.propTypes = {
  store: PropTypes.object,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  updateTime: PropTypes.number
}

export default inject('store')(observer(Heartbeat))
