import React, { Component } from 'react'
import { Jumbotron } from 'react-bootstrap'
import WineList from './WineList'
import { inject, observer } from 'mobx-react'
import WineDetail from './WineDetail'

class Home extends Component {
  render () {
    const wines = this.props.store.wines
    const spotlightWines = wines.filter(wine => wine.spotlight)
    const spotlightWine = spotlightWines.length > 0 ? spotlightWines[0] : null

    return (
      <div>
        <Jumbotron style={{
          backgroundImage: 'url(/images/ww.jpg)',
          minHeight: '50%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
        >
          <h1 style={{ marginLeft: 50, color: 'white' }}>Wheeler Wines</h1>
          <h3 style={{ marginLeft: 50, color: 'white' }}>Exceptional wines at their price point.</h3>
        </Jumbotron>
        <div className='container marketing'>
          {spotlightWine
            ? <WineDetail
                wine={spotlightWine}
                user={this.props.store.user}
                history={this.props.history}
                showSpotlight
              />
            : null}
          <div className='row'>
            <div className='col-lg-12'>
              <h2>An Introduction</h2>
              <p>Hello! Welcome to Wheeler Wines. If you know Julian already, then you can get straight to the Wine List. Use the wine colour/type buttons to make for easier browsing. For those who don’t, I am a self-taught wine enthusiast with about 30 years experience of buying, keeping and drinking wines of an increasingly diverse nature. We offer a very personalised, independent, selection of mostly lesser known wines, that offer good value at most budgets. I urge you to be adventurous; all these wines are worth trying a bottle at the very least, because of my recommendation. Why? Because I have no loyalty to, or commitment to list, any particular wine and so they can and do get replaced if I find something better at the equivalent or lower price.</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <h2>Genuine Value</h2>
              <p>Many companies say they offer value, with gimmicky promotions and so-called sales, but we actually mean it. This is our guarantee:</p>
              <h4>'You can always buy a cheaper wine - but you cannot buy these wines cheaper.'</h4>
              <p>Value is not just a monetary measure; Wheeler Wines have done the ‘mouth work’ for you, sampling literally hundreds of wines to narrow the field and bring you a fine and diverse selection of exceptional wines at all price points. Because of the ‘Club like’ exclusive nature of this site, some of these wines are intended for the 'on-trade' and not usually available to retail customers.</p>
            </div>
            <div className='col-lg-6'>
              <h2>Wine Concierge</h2>
              <p>In general we try to stock less well known wines from smaller producers, because they are usually better value and you enjoy what's in the bottle rather than what’s on the label. The Wine List represents our core selection, but you can just email us with any specific requests and we can track down a particular favourite, or try to offer you a legitimate alternative. While most wine companies offer courier delivery, for London orders Julian uniquely arranges to deliver by hand whenever possible, avoiding the need to wait in for half a day! <u>Clicking your Login name brings up an Order History</u>, which helps you to remember previous wines purchased, while the Events page shows other bespoke offerings from Wheeler Wines.</p>
            </div>
          </div>
        </div>
        <WineList display={false} />
      </div>
    )
  }
}

export default inject('store')(observer(Home))
