import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import formatPrice from '../utils/formatPrice'

class CartRow extends Component {
  constructor (props) {
    super(props)

    this.state = {
      qty: props.qty
    }
  }

  handleIncrement = () => {
    const newVal = this.state.qty + 1
    this.props.changeQty(newVal)
    this.setState({
      qty: newVal
    })
  }

  handleDecrement = () => {
    const newVal = Math.max(this.state.qty - 1, 0)
    this.props.changeQty(newVal)
    this.setState({
      qty: newVal
    })
  }

  handleChange = (e) => {
    const newVal = e.target.value
    this.props.changeQty(newVal)
    this.setState({
      qty: newVal
    })
  }

  render () {
    const { name, vintage, price } = this.props

    return (
      <tr>
        <td style={{ verticalAlign: 'middle' }}>
          {`${name} ${vintage}`}
        </td>
        <td className='text-right'>
          <div className='input-group input-group-sm' style={{ float: 'right', width: 110 }}>
            <span className='input-group-addon' onClick={this.handleDecrement}><span className='glyphicon glyphicon-minus' /></span>
            <input className='form-control text-right' type='text' value={this.state.qty} onChange={this.handleChange} />
            <span className='input-group-addon' onClick={this.handleIncrement}><span className='glyphicon glyphicon-plus' /></span>
          </div>
        </td>
        <td className='text-right' style={{ verticalAlign: 'middle' }}>
          {formatPrice('GBP', price)}
        </td>
        <td className='text-right' style={{ verticalAlign: 'middle', minWidth: 100 }}>
          {formatPrice('GBP', price * this.state.qty)}
        </td>
      </tr>
    )
  }
}

CartRow.propTypes = {
  name: PropTypes.string.isRequired,
  vintage: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  qty: PropTypes.number.isRequired,
  changeQty: PropTypes.func.isRequired
}

export default CartRow
