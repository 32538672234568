import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { inject, observer } from 'mobx-react'
import logger from '../utils/logger'

class WineEdit extends Component {
  constructor (props) {
    super(props)

    this.state = Object.assign({}, this.props.wine)
  }

  onBack = () => {
    this.props.history.goBack()
  }

  onSave = () => {
    const wine = this.state

    wine.description = wine.description.replace(/\n/g, '<CR>')

    this.props.store.saveWine(this.state, (err, message) => {
      if (err) {
        logger.error(err.data)
      } else {
        this.props.history.goBack()
      }
    })
  }

  handleChange = (e) => {
    const { name, value, type } = e.currentTarget
    const nextState = {}

    switch (type) {
      case 'checkbox':
        nextState[name] = !this.state[name]
        break
      case 'number':
        nextState[name] = Number(value)
        break
      default:
        nextState[name] = value
        break
    }

    this.setState(nextState)
  }

  render () {
    const { user } = this.props.store
    if (!user || !user.administrator) {
      return (
        <div>NOT AUTHORISED</div>
      )
    }

    const { wine } = this.props
    if (!wine) {
      return (
        <div>Not found</div>
      )
    }

    const backgroundColor = this.state.live ? 'white' : 'grey'

    return (
      <div className='container' style={{ marginTop: 10 }}>
        <div className='row' style={{ marginBottom: 20 }}>
          <button className='btn btn-default' onClick={this.onBack} style={{ marginRight: 10 }}>Cancel</button>
          <button className='btn btn-default' onClick={this.onSave}>Save</button>
        </div>
        <div className='thumbnail' style={{ backgroundColor }}>
          <div className='caption'>
            <div style={{ display: 'table' }}>
              <div style={{ display: 'table-column' }} />
              <div style={{ display: 'table-column' }} />
              <div style={{ display: 'table-row' }}>
                <div style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center', minWidth: 200 }}>
                  <img style={{ width: 'auto', height: 190 }} src={`/bottles/${wine.image}`} alt='bottle' />
                </div>
                <div style={{ display: 'table-cell' }}>
                  <div style={{ height: '48px' }}>
                    <input type='text' name='name' onChange={this.handleChange} value={this.state.name} style={{ width: 300, marginRight: 5, backgroundColor }} />
                    <input type='text' name='vintage' onChange={this.handleChange} value={this.state.vintage} style={{ width: 100, marginRight: 5, backgroundColor }} />
                    <input type='text' name='country' onChange={this.handleChange} value={this.state.country} style={{ marginRight: 5, backgroundColor }} />
                    <input type='number' name='stars' onChange={this.handleChange} value={this.state.stars || ''} style={{ backgroundColor }} placeholder='Stars' />
                  </div>
                  <p className='card-text'>
                    <textarea name='header' onChange={this.handleChange} value={this.state.header} style={{ width: '100%', backgroundColor }} />
                  </p>
                  <p className='card-text'>
                    <textarea name='description' onChange={this.handleChange} value={this.state.description.replace(/<CR>/g, '\n')} style={{ width: '100%', minHeight: 100, backgroundColor }} />
                  </p>
                  <input type='text' name='image' onChange={this.handleChange} value={this.state.image} style={{ width: 300, marginRight: 5, backgroundColor }} />
                  <div>
                    <input type='number' name='price' style={{ textAlign: 'right', marginRight: 10, backgroundColor }} onChange={this.handleChange} value={this.state.price || ''} placeholder='Price' />
                    <span><input type='checkbox' name='live' onChange={this.handleChange} value={this.state.live} checked={this.state.live} style={{ backgroundColor }} /> Live</span>
                    <span><input type='checkbox' name='spotlight' onChange={this.handleChange} value={this.state.spotlight} checked={this.state.spotlight} style={{ marginLeft: 10, backgroundColor }} /> Spotlight</span>
                  </div>
                  <div>
                    <input type='number' name='jOrder' style={{ textAlign: 'right', marginRight: 10, backgroundColor }} onChange={this.handleChange} value={this.state.jOrder || ''} placeholder='Order' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

WineEdit.propTypes = {
  wine: PropTypes.object.isRequired,
  user: PropTypes.object,
  history: PropTypes.object.isRequired
}

export default inject('store')(observer(WineEdit))
