import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Image } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import defaultProfilePicture from '../images/profile.png'
import '../css/App.css'

class NavBar extends Component {
  render () {
    const { userCode, administrator, pictureURL } = this.props

    return (
      <nav className='navbar navbar-inverse navbar-fixed-top navBarOverride'>
        <div className='container'>
          <div className='navbar-header'>
            <button type='button' className='navbar-toggle collapsed' data-toggle='collapse' data-target='#navbar' aria-expanded='false' aria-controls='navbar'>
              <span className='sr-only'>Toggle navigation</span>
              <span className='icon-bar' />
              <span className='icon-bar' />
              <span className='icon-bar' />
            </button>

            <NavLink style={{ paddingTop: 0 }} role='button' to='/'>
              <span
                className='navbar-brand'
                style={{
                  textAlign: 'center',
                  fontFamily: 'serif',
                  fontWeight: 'bold',
                  fontSize: '24px',
                  fontVariant: 'small-caps',
                  paddingTop: 5
                }}
              >
                <div>
                  Wheeler
                </div>
                <div style={{ fontSize: '28px' }}>
                  Wines
                </div>
              </span>
            </NavLink>
          </div>

          <div id='navbar' className='navbar-collapse collapse'>
            <ul className='nav navbar-nav'>
              <li><NavLink role='button' to='/wines'>Wine List</NavLink></li>
              <li><NavLink role='button' to='/events'>Events</NavLink></li>
              <li><NavLink role='button' to='/contact'>Contact us</NavLink></li>
            </ul>
            {
              userCode
                ? (
                  <ul className='nav navbar-nav navbar-right'>
                    <li>
                      <NavLink role='button' to='/cart' style={{ paddingBottom: 0 }}>
                        <img style={{ position: 'relative', top: -4, height: 30, overflow: 'hidden', marginRight: 5 }} src='/images/cart.svg' alt='cart' />
                        <span style={{ position: 'relative', top: -4 }}>{`[${this.props.store.cart.reduce((count, item) => (count += item.qty), 0)} bottles]`}</span>
                      </NavLink>
                    </li>
                    {administrator
                      ? (
                        <li className='dropdown'>
                          <a href='#' className='dropdown-toggle' data-toggle='dropdown' role='button' aria-haspopup='true' aria-expanded='false'>Administration <span className='caret' /></a>
                          <ul className='dropdown-menu'>
                            <li><NavLink to='/admin/users'>Users</NavLink></li>
                            <li><NavLink to='/admin/orders'>Orders</NavLink></li>
                          </ul>
                        </li>
                        )
                      : null}
                    <li>
                      <Image
                        className='hidden-xs'
                        width='32px'
                        height='32px'
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                        src={pictureURL || defaultProfilePicture}
                        circle
                      />
                    </li>
                    <li className='dropdown'>
                      <a href='#' className='dropdown-toggle' data-toggle='dropdown' role='button' aria-haspopup='true' aria-expanded='false'>{userCode} <span className='caret' /></a>
                      <ul className='dropdown-menu'>
                        <li><NavLink to='/history'>Order history</NavLink></li>
                        {/* <li><NavLink to='/cellar'>My Cellar</NavLink></li> */}
                        <li><NavLink to='/logout'>Log out</NavLink></li>
                      </ul>
                    </li>
                  </ul>
                  )
                : (
                  <ul className='nav navbar-nav navbar-right'>
                    <li>
                      <NavLink role='button' to='/login'>Login</NavLink>
                    </li>
                  </ul>
                  )
            }
          </div>
        </div>
      </nav>
    )
  }
}

NavBar.propTypes = {
  userCode: PropTypes.string,
  administrator: PropTypes.bool.isRequired,
  pictureURL: PropTypes.string
}

export default inject('store')(observer(NavBar))
