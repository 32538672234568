import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { inject, observer } from 'mobx-react'
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table'
import Email from './Email'
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import Spinner from '../components/common/Spinner'
import logger from '../utils/logger'
// import './Admin.css'
import ConnectionManager from '../ConnectionManager'

class Users extends Component {
  constructor (props) {
    super(props)

    this.state = {
      queryState: 'loading',
      users: []
    }

    this.users = []
  }

  componentWillUnmount () {
    if (this.connectionManager) {
      this.connectionManager.closeSocket()
    }
  }

  componentDidMount () {
    this.connectionManager = ConnectionManager('/users', this.props.store)

    this.connectionManager.sendRequest({
      method: 'all',
      keepAlive: true,
      cb: (err, message) => {
        if (err) {
          logger.error('ERROR', err)
        } else {
          switch (message.type) {
            case 'state':
              if (message.data === 'ready') {
                this.setState({
                  queryState: 'ready',
                  users: this.users
                })
                this.users = null
              }
              break
            case 'data':
              switch (this.state.queryState) {
                case 'loading':
                  this.users.push(message.data)
                  break
                case 'ready': {
                  const u = this.state.users.filter((user) => user.userCode !== message.data.userCode)
                  u.push(message.data)
                  this.setState({
                    users: u
                  })
                  break
                }
                default:
                  throw new Error('Unexpected state')
              }
              break
            case 'dataDeleted':
              this.setState({
                users: this.state.users.filter((user) => user.userCode !== message.data)
              })
              break
            default:
              throw new Error('Unexpected case ', message.type)
          }
        }
      }
    })
  }

  createCustomButtonGroup (props) {
    return (
      <ButtonGroup className='my-custom-class' sizeClass='btn-group-md'>
        {props.showSelectedOnlyBtn}
        {props.exportCSVBtn}
        {props.insertBtn}
        {props.deleteBtn}
        <button
          type='button'
          className='btn btn-primary' data-toggle='modal' data-target='#emailModal'
        >
          <i className='fa glyphicon glyphicon-envelope fa-envelope' /> Send email...
        </button>
      </ButtonGroup>
    )
  }

  dateFormatter (cell, row) {
    if (cell) {
      return new Date(cell)
    }
    return 'Not yet confirmed'
  }

  onAddRow (row) {
    if (row.administrator === 'true') {
      row.administrator = true
    }

    if (row.credit === 'true') {
      row.credit = true
    }

    ConnectionManager('/users', this.props.store).sendRequest({
      method: 'register',
      params: {
        data: row
      },
      cb: (err, message) => {
        if (err) {
          logger.error(err.data)
        }
      }
    })
  }

  onDeleteRow (rowKey) {
    const response = window.prompt('Please enter "YES" to confirm')
    if (response && response === 'YES') {
      rowKey.forEach((id) => {
        ConnectionManager('/users', this.props.store).sendRequest({
          method: 'delete',
          params: {
            userCode: id
          },
          cb: (err, message) => {
            if (err) {
              logger.error(err, message)
            }
          }
        })
      })
    }
  }

  remote (remoteObj) {
    // Only insert and delete row will be handled by remote store
    remoteObj.insertRow = true
    remoteObj.dropRow = true
    return remoteObj
  }

  checkboxFormatter (value, row, index) {
    console.log(value, row, index)
    return (
      <input type='checkbox' value={value} />
    )
  }

  emailSelectedRows (text, subject) {
    ConnectionManager('/users', this.props.store).sendRequest({
      method: 'mailshot',
      params: {
        data: {
          emails: this.refs.table.state.selectedRowKeys,
          text,
          subject
        }
      },
      cb: (err, message) => {
        if (err) {
          logger.error(err, message)
        }
      }
    })
  }

  render () {
    if (this.state.queryState === 'loading') {
      return <Spinner text='Loading...' className='spaceAfter ' />
    }

    console.log('state after', this.state)
    const selectRow = {
      mode: 'checkbox',
      bgColor: 'rgb(238, 193, 213)'
    }

    const cellEdit = {
      mode: 'click',
      blurToSave: true,
      afterSaveCell: (row, cellName, cellValue) => {
        if (row.administrator === 'true') {
          row.administrator = true
        }

        if (row.live === 'true') {
          row.live = true
        }

        if (row.credit === 'true') {
          row.credit = true
        }

        ConnectionManager('/users', this.props.store).sendRequest({
          method: 'update',
          params: {
            data: row
          },
          cb: (err, message) => {
            if (err) {
              logger.error(err.data)
            }
          }
        })
      }
    }

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2>Users <span style={{ fontSize: 'small' }}>{`${this.state.users.filter(u => u.confirmedAt).length} confirmed out of ${this.state.users.length}`}</span></h2>
            <BootstrapTable
              selectRow={selectRow}
              cellEdit={cellEdit}
              insertRow
              deleteRow
              exportCSV
              data={this.state.users.sort((a, b) => a.userCode > b.userCode)}
              striped
              hover
              ref='table'
              remote={this.remote}
              formatter={this.checkboxFormatter}
              options={{
                sortIndicator: false,
                onAddRow: this.onAddRow.bind(this),
                onDeleteRow: this.onDeleteRow.bind(this),
                btnGroup: this.createCustomButtonGroup
              }}
            >
              <TableHeaderColumn dataField='live' hiddenOnInsert checkbox editable={{ type: 'checkbox', options: { values: 'true:false' } }} dataSort>Live</TableHeaderColumn>
              <TableHeaderColumn isKey dataField='userCode' dataSort>User</TableHeaderColumn>
              <TableHeaderColumn dataField='firstName' dataSort>First name</TableHeaderColumn>
              <TableHeaderColumn dataField='lastName' dataSort>Last name</TableHeaderColumn>
              <TableHeaderColumn dataField='email' dataSort>Email address</TableHeaderColumn>
              <TableHeaderColumn dataField='credit' checkbox editable={{ type: 'checkbox', options: { values: 'true:false' } }} dataSort>Credit</TableHeaderColumn>

              <TableHeaderColumn dataField='administrator' checkbox editable={{ type: 'checkbox', options: { values: 'true:false' } }} dataSort>Administrator</TableHeaderColumn>
              <TableHeaderColumn dataField='registeredAt' hiddenOnInsert dataFormat={this.dateFormatter} dataSort>Registered</TableHeaderColumn>
              <TableHeaderColumn dataField='confirmedAt' hiddenOnInsert dataFormat={this.dateFormatter} dataSort>Confirmed</TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
        <Email onSubmit={this.emailSelectedRows.bind(this)} />
      </div>
    )
  }
}

Users.propTypes = {
  store: PropTypes.object
}

export default inject('store')(observer(Users))
