import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import formatPrice from '../utils/formatPrice'
import debounce from 'lodash/debounce'

class OrderRow extends Component {
  handleIncrement = () => {
    this.persist(this.props.item.qtyDelivered + 1)
  }

  handleDecrement = () => {
    this.persist(Math.max(this.props.item.qtyDelivered - 1, 0))
  }

  handleChange = (e) => {
    this.persist(e.target.value)
  }

  persist = debounce((qtyDelivered) => {
    this.props.updateDelivered(Number(qtyDelivered))
  }, 1)

  render () {
    const { item } = this.props
    const { name, vintage, price, qty, qtyDelivered } = item

    const rowColor = qtyDelivered >= qty ? 'silver' : 'blue'
    return (
      <tr style={{ color: rowColor }}>
        <td style={{ verticalAlign: 'middle' }}>
          {`${name} ${vintage}`}
        </td>
        <td className='text-right' style={{ verticalAlign: 'middle' }}>
          {qty}
        </td>
        <td className='text-right'>
          <div className='input-group input-group-sm' style={{ float: 'right', width: 110 }}>
            <span className='input-group-addon' onClick={this.handleDecrement}><span className='glyphicon glyphicon-minus' /></span>
            <input className='form-control text-right' type='text' value={qtyDelivered || 0} onChange={this.handleChange} style={{ color: rowColor }} />
            <span className='input-group-addon' onClick={this.handleIncrement}><span className='glyphicon glyphicon-plus' /></span>
          </div>
        </td>
        <td className='text-right' style={{ verticalAlign: 'middle' }}>
          {formatPrice('GBP', price)}
        </td>
        <td className='text-right' style={{ verticalAlign: 'middle', minWidth: 100 }}>
          {formatPrice('GBP', price * qty)}
        </td>
      </tr>
    )
  }
}

OrderRow.propTypes = {
  item: PropTypes.object.isRequired,
  updateDelivered: PropTypes.func.isRequired
}

export default OrderRow
