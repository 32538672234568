import React, { Component } from 'react'
import { inject, observer, Provider } from 'mobx-react'
import store from './store'
import { BrowserRouter } from 'react-router-dom'
import NavBar from './components/NavBar'
import Footer from './components/Footer'
import routes from './routes'

class App extends Component {
  render () {
    // return <OldHome />
    // Have to reference the token here in order to get mobx to hook in, won't spot it in the Router
    const { user, token } = store
    return (
      <Provider store={store}>
        <BrowserRouter>
          <div id='app'>
            <NavBar
              userCode={user ? user.userCode : null}
              administrator={user ? user.administrator === true : false}
              pictureURL={user ? user.pictureURL : null}
            />
            <div id='appContent'>
              {routes(user, token)}
            </div>
            <Footer userCode={user ? user.userCode : null} />
          </div>
        </BrowserRouter>
      </Provider>
    )
  }
}

export default inject('store')(observer(App))
