import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import {
  Form,
  FormGroup,
  Col,
  FormControl,
  Button
} from 'react-bootstrap'
import { inject, observer } from 'mobx-react'

class Login extends Component {
  constructor (props) {
    super(props)

    this.state = {
      redirectToReferrer: false,
      userCode: this.props.store.lastUserCode || '',
      password: ''
    }
  }

  handleChange = (e) => {
    const { name, value } = e.currentTarget
    const nextState = {}

    nextState[name] = value

    this.setState(nextState)
  }

  login = () => {
    const {
      userCode,
      password
    } = this.state
    this.props.store.login({ userCode, password })
  }

  render () {
    if (this.props.store.user && this.props.store.token) {
      try {
        this.props.history.goBack()
      } catch (err) {
        // Ignore
      }
      return null
    }

    const loginState = this.props.store.loginState
    const error = loginState ? loginState.error : null
    const isLoggingIn = loginState ? loginState.isLoggingIn : false

    return (
      <div>
        <Col sm={4} smPush={4} style={{ backgroundColor: 'rgba(192, 192, 192, 0.5)', borderRadius: '4px', marginTop: '40px' }}>
          <div style={{ textAlign: 'center' }}>
            <h1>Wheeler Wines</h1>
            <h2>Log In</h2>
            <hr />
          </div>
          <Form horizontal>
            <FormGroup>
              <FormGroup>
                <Col xs={8} xsPush={2}>
                  <FormControl
                    autoFocus={!this.state.userCode}
                    type='username'
                    name='userCode'
                    placeholder='Username'
                    value={this.state.userCode}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        this.login.bind(this)()
                      }
                    }}
                    onChange={this.handleChange.bind(this)}
                  />
                </Col>
              </FormGroup>

              <FormGroup>
                <Col xs={8} xsPush={2}>
                  <FormControl
                    autoFocus={this.state.userCode}
                    type='password'
                    name='password'
                    placeholder='Password'
                    value={this.state.password}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        this.login.bind(this)()
                      }
                    }}
                    onChange={this.handleChange.bind(this)}
                  />
                </Col>
              </FormGroup>
            </FormGroup>
            {error && !isLoggingIn && <div style={{ textAlign: 'center', color: '#a94442' }}>{error}</div>}
            <hr />
            <FormGroup>
              <Col xs={8} xsPush={2}>
                <Button
                  bsStyle='success'
                  bsSize='large'
                  disabled={isLoggingIn}
                  style={{ width: '100%' }}
                  onClick={this.login.bind(this)}
                >
                  {isLoggingIn ? <i className='fa fa-cog fa-spin' /> : <i />}
                  {isLoggingIn ? ' Logging In' : 'Log In'}
                </Button>
              </Col>
            </FormGroup>
          </Form>
          <p className='small'>To request an account, please contact us <a href='#' onClick={() => this.props.history.push('/contact')}>here</a>.</p>
          {/* <p className='small'>Forgot password?  Click <a href='#' onClick={() => this.props.history.push('/resetpassword')}>here</a> to reset it.</p> */}
        </Col>
      </div>
    )
  }
}

Login.propTypes = {
  store: PropTypes.object,
  history: PropTypes.object.isRequired
}

export default inject('store')(observer(Login))
