import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import './Spinner.css'

export default class Spinner extends Component {
  render () {
    const className = 'Spinner ' + this.props.className

    return (
      <div className={className}>
        {(this.props.text)
          ? <p className='Spinner spaceAfter'>{this.props.text}</p>
          : null}
        <i className='fa glyphicon glyphicon-refresh fa-refresh' />
      </div>
    )
  }
}

Spinner.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
}
