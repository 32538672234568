import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import formatPrice from '../utils/formatPrice'

class HistoryRow extends Component {
  constructor (props) {
    super(props)

    this.state = {
      qty: props.qty
    }
  }

  render () {
    const { name, vintage, price, qty } = this.props

    return (
      <tr>
        <td style={{ verticalAlign: 'middle' }}>
          {`${name} ${vintage}`}
        </td>
        <td className='text-right' style={{ verticalAlign: 'middle' }}>
          {qty}
        </td>
        <td className='text-right' style={{ verticalAlign: 'middle' }}>
          {formatPrice('GBP', price)}
        </td>
        <td className='text-right' style={{ verticalAlign: 'middle', minWidth: 100 }}>
          {formatPrice('GBP', price * qty)}
        </td>
      </tr>
    )
  }
}

HistoryRow.propTypes = {
  name: PropTypes.string.isRequired,
  vintage: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  qty: PropTypes.number.isRequired
}

export default HistoryRow
