import React, { Component } from 'react'
import { PropTypes } from 'prop-types'

class Flag extends Component {
  render () {
    switch (this.props.countryCode) {
      case 'GB':
        return <span role='img' aria-label='great britain'>🇬🇧</span>
      case 'FR':
        return <span role='img' aria-label='france'>🇫🇷</span>
      case 'IT':
        return <span role='img' aria-label='italy'>🇮🇹</span>
      case 'ZA':
        return <span role='img' aria-label='south africa'>🇿🇦</span>
      case 'AU':
        return <span role='img' aria-label='australia'>🇦🇺</span>
      case 'BG':
        return <span role='img' aria-label='bulgaria'>🇧🇬</span>
      case 'CL':
        return <span role='img' aria-label='chile'>🇨🇱</span>
      case 'PT':
        return <span role='img' aria-label='portugal'>🇵🇹</span>
      case 'ES':
        return <span role='img' aria-label='spain'>🇪🇸</span>
      case 'NZ':
        return <span role='img' aria-label='new zealand'>🇳🇿</span>
      case 'GR':
        return <span role='img' aria-label='greece'>🇬🇷</span>
      case 'DE':
        return <span role='img' aria-label='germany'>🇩🇪</span>
      default:
        return <span />
    }
  }
}

Flag.propTypes = {
  countryCode: PropTypes.string
}

export default Flag
