import React, { Component } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import NoPropLineItem from './NoPropLineItem'
import Heartbeat from './Heartbeat'

class Footer extends Component {
  render () {
    return (
      <Navbar
        fixedBottom
        inverse
        style={{ minHeight: '35px', maxHeight: '35px', paddingLeft: '5px', paddingRight: '15px' }}
        className='navBarOverride'
      >
        <Nav style={{ marginTop: '7px', marginBottom: '5px' }}>
          <NoPropLineItem>
            <div style={{ color: 'white' }}>
              {new Date().toGMTString().match(/\w{3}, \d{2} (.* \d{4}).*$/)[1]}
            </div>
          </NoPropLineItem>
        </Nav>
        <Nav pullRight className='hidden-xs' style={{ marginTop: '7px', marginBottom: '5px' }}>
          <NoPropLineItem>
            <div style={{ float: 'left', color: 'white' }}>
              © 2016-{new Date().getFullYear()}<a style={{ marginLeft: 10, marginRight: 10 }} href='http://masa.gi'>Masagi Limited</a>All rights reserved.
            </div>
          </NoPropLineItem>
        </Nav>
        <Nav pullRight className='hidden-xs' style={{ marginTop: '7px', marginBottom: '5px' }}>
          <NoPropLineItem>
            <Heartbeat width={40} height={15} updateTime={500} />
          </NoPropLineItem>
        </Nav>

      </Navbar>
    )
  }
}

export default Footer
