import React, { Component } from 'react'
import { PropTypes } from 'prop-types'

class CellarDetails extends Component {
  handleClick = () => {
    const data = Object.assign({}, this.props.cellar, { name: new Date() })
    console.log(data)
    this.props.saveCellar(data)
  }

  render () {
    console.log(JSON.stringify(this.props.cellar))
    return (
      <div>
        <label>Name:</label><input value={this.props.cellar.name} />
        <table className='table' style={{ marginTop: 20 }}>
          <thead className='thead-primary'>
            <tr>
              <th>Name</th>
              <th className='text-right'>Qty</th>
            </tr>
          </thead>
          <tbody>
            {this.props.cellar.wines.sort((a, b) => (a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0).map(wine => {
              return (
                <tr key={wine.id}>
                  <td>{wine.name}</td>
                  <td className='text-right'>{wine.qty}</td>
                </tr>
              )
            })}
          </tbody>
        </table>

        <button onClick={this.handleClick}>Save</button>
      </div>
    )
  }
}

CellarDetails.propTypes = {
  cellar: PropTypes.object.isRequired,
  saveCellar: PropTypes.func.isRequired
}

export default CellarDetails
