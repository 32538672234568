import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

class Contact extends Component {
  constructor (props) {
    super(props)

    if (props.store.name === '' && props.store.user) {
      props.store.name = `${props.store.user.firstName} ${props.store.user.lastName}`
    }

    if (props.store.email === '' && props.store.user) {
      props.store.email = props.store.user.email
    }

    this.state = {
      errName: null,
      errEmail: null,
      errMessage: null,
      result: ''
    }
  }

  onNameChange = (e) => {
    this.props.store.name = e.target.value
  }

  onEmailChange = (e) => {
    this.props.store.email = e.target.value
  }

  onMessageChange = (e) => {
    this.props.store.message = e.target.value
  }

  render () {
    return (
      <div className='container'>
        <div className='row'>
          <aside style={{ marginTop: 20 }}>You will need an account to place orders with us.  Simply contact us with one of the methods below and we will create an account for you.  You will then receive a confirmation email with a link that allows you to activate the account and choose a password.</aside>
          <h4 style={{ marginTop: 20 }}>You can contact us at the following email address:</h4>
          <p>Julian: <a href='mailto:julian@wheelerwines.com'>julian@wheelerwines.com</a></p>
          <h4 style={{ marginTop: 20, marginBotton: 20 }}>Or send a message to us here:</h4>
        </div>
        <div className='row'>
          <div className='col-md-6 col-md-offset-3'>
            <form className='form-horizontal' method='post' action='/mail'>
              <div className='form-group'>
                <label htmlFor='name' className='col-sm-2 control-label'>Name</label>
                <div className='col-sm-10'>
                  <input type='text' className='form-control' id='name' name='name' placeholder='First & Last Name' value={this.props.store.name} onChange={this.onNameChange.bind(this)} />
                  {this.state.errName ? <p className='text-danger'>{this.state.errName}</p> : null}
                </div>
              </div>
              <div className='form-group'>
                <label htmlFor='email' className='col-sm-2 control-label'>Email</label>
                <div className='col-sm-10'>
                  <input type='email' className='form-control' id='email' name='email' placeholder='example@domain.com' value={this.props.store.email} onChange={this.onEmailChange.bind(this)} />
                  {this.state.errEmail ? <p className='text-danger'>{this.state.errEmail}</p> : null}
                </div>
              </div>
              <div className='form-group'>
                <label htmlFor='message' className='col-sm-2 control-label'>Message</label>
                <div className='col-sm-10'>
                  <textarea className='form-control' rows='4' name='message' value={this.props.store.message} onChange={this.onMessageChange.bind(this)} />
                  {this.state.errMessage ? <p className='text-danger'>{this.state.errMessage}</p> : null}
                </div>
              </div>
              <div className='form-group'>
                <div className='col-sm-10 col-sm-offset-2'>
                  <input id='submit' name='submit' type='submit' value='Send message' className='btn btn-primary' />
                </div>
              </div>
              <div className='form-group'>
                <div className='col-sm-10 col-sm-offset-2'>
                  {this.state.result}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default inject('store')(observer(Contact))
