import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { inject, observer } from 'mobx-react'
import WineCard from './WineCard'
import WineDetail from './WineDetail'
import WineEdit from './WineEdit'

class WineList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      filterText: '',
      type: 'all',
      showHidden: false
    }
  }

  country = (countryCode) => {
    switch (countryCode) {
      case 'GB':
        return 'uk united kingdom britain british'
      case 'FR':
        return 'france french'
      case 'IT':
        return 'italy italian'
      case 'ZA':
        return 'south africa african'
      case 'AU':
        return 'australia australian'
      case 'BG':
        return 'bulgaria bulgarian'
      case 'CL':
        return 'chile chilean'
      case 'PT':
        return 'portugal portuguese'
      case 'ES':
        return 'spain spanish'
      case 'NZ':
        return 'new zealand'
      case 'GR':
        return 'greek greece'
      case 'DE':
        return 'germany'
      default:
        return ''
    }
  }

  handleShowHidden = () => {
    this.setState({
      showHidden: !this.state.showHidden
    })
  }

  handleSearchChange = (e) => {
    this.setState({
      filterText: e.target.value
    })
  }

  setType = (type) => {
    return (e) => {
      this.setState({
        type
      })
    }
  }

  render () {
    let display = true
    if (this.props.display === false) {
      display = false
    }
    const { user, wines } = this.props.store

    const { match } = this.props

    if (match && match.params.wineid) {
      if (match.url.endsWith('/edit')) {
        return (
          <WineEdit
            wine={wines.find(wine => wine.id === match.params.wineid)}
            user={user}
            history={this.props.history}
          />
        )
      } else {
        return (
          <WineDetail
            wine={wines.find(wine => wine.id === match.params.wineid)}
            user={user}
            history={this.props.history}
            showSpotlight={false}
          />
        )
      }
    }

    return (
      <div className='container' style={{ marginTop: 10 }}>
        {display
          ? (
            <ul className='nav nav-pills' style={{ marginBottom: 10 }}>
              <li role='presentation' className={this.state.type === 'all' ? 'active' : ''}><button className='btn btn-link' onClick={this.setType('all')}>All wines</button></li>
              <li role='presentation' className={this.state.type === 'sparkling' ? 'active' : ''}><button className='btn btn-link' href='#' onClick={this.setType('sparkling')}>Sparkling</button></li>
              <li role='presentation' className={this.state.type === 'white' ? 'active' : ''}><button className='btn btn-link' onClick={this.setType('white')}>White</button></li>
              <li role='presentation' className={this.state.type === 'rose' ? 'active' : ''}><button className='btn btn-link' onClick={this.setType('rose')}>Rosé</button></li>
              <li role='presentation' className={this.state.type === 'red' ? 'active' : ''}><button className='btn btn-link' onClick={this.setType('red')}>Red</button></li>
              <li>
                <form className='navbar-form' style={{ marginTop: 3 }}>
                  <input className='form-control' type='text' placeholder='Search' value={this.state.filterText} onChange={this.handleSearchChange} title='Search by country, region or name of wine' />
                </form>
              </li>
              <li>
                <h4 style={{ marginTop: 5, marginBottom: 3 }}>All prices include VAT and free delivery<sup>*</sup> in central London</h4>
                <p className='small'>
                  <sup>* </sup>Minimum order of 12 bottles or £100.
                  {user && user.administrator ? <span><input style={{ marginLeft: 10 }} type='checkbox' name='showHidden' onChange={this.handleShowHidden} value={this.state.showHidden} checked={this.state.showHidden} /> Show hidden</span> : null}
                </p>
              </li>
            </ul>
            )
          : null}

        <div className='row'>
          {wines.filter(wine => {
            return (this.state.type === 'all' || wine.type === this.state.type) && (wine.live || this.state.showHidden) &&
            `${wine.name}|${wine.header}|${wine.vintage}|${this.country(wine.country)}`.toUpperCase().match(this.state.filterText.toUpperCase())
          })
            .sort((a, b) => {
              const types = ['sparkling', 'white', 'rose', 'red']
              const aType = types.indexOf(a.type)
              const bType = types.indexOf(b.type)
              if (aType === bType) {
                return a.jOrder - b.jOrder
              } else {
                return aType - bType
              }
            })
            .map(wine => <WineCard key={wine.id} display={display} wine={wine} history={this.props.history} />)}
        </div>
      </div>
    )
  }
}

WineList.propTypes = {
  display: PropTypes.bool,
  store: PropTypes.object
}

export default inject('store')(observer(WineList))
