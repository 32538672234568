import React, { Component } from 'react'
import { PropTypes } from 'prop-types'

class Email extends Component {
  constructor (props) {
    super(props)

    this.state = {
      message: `Dear {firstName}
      
This is a test message from WheelerWines to see if our email service is working.

Many thanks

Julian
`,
      subject: 'Message from WheelerWines'
    }
  }

  onSubjectChange (e) {
    this.setState({
      subject: e.target.value
    })
  }

  onMessageChange (e) {
    this.setState({
      message: e.target.value
    })
  }

  submit () {
    this.closeButton.click()
    this.props.onSubmit(this.state.message, this.state.subject)
  }

  render () {
    return (
      <div className='modal fade' id='emailModal' tabIndex='-1' role='dialog' aria-labelledby='emailModal'>
        <div className='modal-dialog' style={{ width: 800 }} role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>&times;</span></button>
              <h4 className='modal-title' id='myModalLabel'>Email customers</h4>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='form-group' style={{ padding: 30 }}>
                  <p className='small'>{'Available tags are {confirmToken}, {email}, {firstName}, {lastName}'}</p>
                  <p>For example, to send a confirmation link you would have something like:</p>
                  <p>{'Your confirmation link is https://wheelerwines.com/confirm/{confirmToken}'}</p>
                </div>
              </div>
              <div className='row'>
                <div className='form-group'>
                  <label htmlFor='subject' className='col-sm-2 control-label'>Email</label>
                  <div className='col-sm-10'>
                    <input type='subject' className='form-control' id='subject' name='subject' placeholder='example@domain.com' value={this.state.subject} onChange={this.onSubjectChange.bind(this)} />
                  </div>
                </div>
                <div className='form-group'>
                  <label htmlFor='message' className='col-sm-2 control-label'>Message</label>
                  <div className='col-sm-10'>
                    <textarea className='form-control' rows='4' name='message' value={this.state.message} onChange={this.onMessageChange.bind(this)} />
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button ref={element => (this.closeButton = element)} type='button' className='btn btn-default' data-dismiss='modal'>Close</button>
              <button type='button' className='btn btn-primary' onClick={this.submit.bind(this)} data-dismiss>Save changes</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Email.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default Email
