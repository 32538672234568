import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { inject, observer } from 'mobx-react'
import WineRack from './WineRack'
import StripeCheckout from 'react-stripe-checkout'
import ConnectionManager from '../ConnectionManager'
import formatPrice from '../utils/formatPrice'
import CartRow from './CartRow'
import Spinner from './common/Spinner'

class Cart extends Component {
  constructor (props) {
    super(props)

    this.state = {
      stage: 'display',
      message: '',
      comments: ''
    }
  }

  handleBack = () => {
    this.props.history.goBack()
  }

  handleClear = () => {
    // runInAction('clear the cart', () => {
    this.props.store.cart = []
  // })
  }

  handlePlaceOrder = () => {
    return this.onToken(null)
  }

  onToken = (token) => {
    this.setState({
      stage: 'paying'
    })
    ConnectionManager('/users', this.props.store).sendRequest({
      method: (token ? 'pay' : 'placeOrder'),
      params: {
        data: {
          token,
          cart: this.props.store.cart,
          comments: this.state.comments,
          amount: Math.round(this.props.store.cart.reduce((total, item) => (total += (item.qty * item.price)), 0) * 100) // Remember Stripe accepts amounts in pence.
        }
      },
      cb: (err, message) => {
        if (err) {
          this.setState({
            stage: 'error',
            message: err.data
          })
        } else {
          this.props.store.cart = []
          this.setState({
            stage: 'success',
            message: message.data
          })
        }
      }
    })
  }

  changeQty = (id, value) => {
    const item = this.props.store.cart.find(item => item.id === id)
    item.qty = Math.max(0, Number(value))
  }

  render () {
    if (this.state.stage === 'error') {
      return (
        <div>
          {`ERROR: ${this.state.message}`}
        </div>
      )
    }

    if (this.state.stage === 'success') {
      return (
        <div className='container'>
          <h4 className='m-4'>Thank you for your order.</h4><h6 className='m-4'>{`Your order reference is ${this.state.message}`}</h6>
          <h4 className='m-4'>Someone from Wheeler Wines will be in touch shortly to arrange delivery.</h4>
          <div className='btn btn-primary m-4' onClick={this.handleBack}>Back</div>
        </div>
      )
    }

    if (this.state.stage === 'paying') {
      return (
        <div className='container'>
          <Spinner />
        </div>
      )
    }

    const amount = this.props.store.cart.reduce((total, item) => (total += (item.qty * item.price)), 0)
    // const totalQty = this.props.store.cart.reduce((total, item) => (total += (item.qty)), 0)
    const totalVolume = this.props.store.cart.reduce((total, item) => (total += (item.size * item.qty)), 0)
    const minAmount = this.props.store.user.userCode === 'ORDISHS' ? 0 : 100

    return (
      <div className='container' style={{ marginTop: 10 }}>
        <div className='row'>
          <button className='btn btn-primary' onClick={this.handleBack}>
            Back
          </button>
        </div>

        <div className='row' style={{ marginTop: 10 }}>
          <table className='table'>
            <thead className='thead-primary'>
              <tr>
                <th style={{ display: 'table', borderBottom: 'none' }}>
                  <h3 style={{ display: 'table-cell' }}>Shopping Basket</h3>
                  <h6 style={{ display: 'table-cell', paddingLeft: 20 }}><a href='#' onClick={this.handleClear}>Clear</a></h6>
                </th>
                <th className='text-right'>Qty</th>
                <th className='text-right'>Price</th>
                <th className='text-right'>Total</th>
              </tr>
            </thead>
            <tbody>
              {this.props.store.cart.map((item) => {
                return (
                  <CartRow
                    key={item.id}
                    name={item.name}
                    qty={item.qty}
                    vintage={'' + item.vintage}
                    price={item.price}
                    changeQty={this.changeQty.bind(this, item.id)}
                  />
                )
              })}
              <tr>
                <td colSpan='3' className='text-right'><h4>Total basket value</h4></td>
                <td className='text-right'><h4>{formatPrice('GBP', amount)}</h4></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className='form-group'>
          <label htmlFor='comment'>Message:</label>
          <textarea
            className='form-control'
            rows='5'
            id='comment'
            placeholder='Send message with this order...'
            value={this.state.comments}
            onChange={(e) => this.setState({ comments: e.target.value })}
          />
        </div>

        <div className='row'>
          <div className='pull-right' style={{ marginBottom: 15 }}>
            {this.state.stage === 'paying'
              ? <div className='btn float-right'>Please wait</div>
              : this.props.store.user && this.props.store.user.credit === true
                ? (
                  <div className='pull-right' style={{ marginBottom: 15 }}>
                    <button onClick={this.handlePlaceOrder} className='btn btn-primary' disabled={totalVolume < 900 && amount < minAmount}>Place order <sup>*</sup></button>
                    <p className='small'><sup>*</sup> Payment to be arranged</p>
                  </div>
                  )
                : (
                  <StripeCheckout
                    disabled={totalVolume < 900 && amount < minAmount}
                    token={this.onToken}
                    name='Wheeler Wines'
                    billingAddress
                    zipCode
                    locale='auto'
                    currency='GBP'
                    amount={Math.round(amount * 100)}
                    stripeKey={process.env.NODE_ENV === 'production' ? 'pk_live_m0wXuD6sBuPohTUsiBbOsQTK' : 'pk_test_e4kluekJHj2xkgbvBU6a6Vun'}
                  >
                    <div>
                      <img style={{ height: 30, width: 'auto', marginRight: 10 }} src='/images/payments.png' alt='credit cards' />
                      <div className='btn btn-primary' disabled={totalVolume < 900 && amount < minAmount}>Pay and place order</div>
                    </div>
                  </StripeCheckout>
                  )}
          </div>
        </div>

        <div className='row'>
          <WineRack cart={this.props.store.cart} />
        </div>
      </div>
    )
  }
}

Cart.propTypes = {
  history: PropTypes.object.isRequired
}

export default inject('store')(observer(Cart))
