import React, { Component } from 'react'
import { Jumbotron } from 'react-bootstrap'

class Events extends Component {
  render () {
    return (
      <div>
        <Jumbotron style={{
          backgroundImage: 'url(/images/tasting4.jpg)',
          minHeight: '50%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          marginTop: 0
        }}
        >
          <h1 style={{ marginLeft: 50, color: 'white' }}>Hosted events</h1>
          <h3 style={{ marginLeft: 50, color: 'white' }}>Enjoyment in drinking and learning.</h3>
        </Jumbotron>
        <div className='container marketing'>
          <div className='row featurette'>
            <div className='col-md-7'>
              <h2 className='featurette-heading'>Tutored tastings in your own home.</h2>
              <p className='lead'>Have you ever tasted and compared eight Pinot Noir wines from around the world at the same time? Is Burgundy really worth the money, especially if we arrange a blind tasting, when you cannot be sure if that’s what you’re drinking? That’s just one example. Recently we re-enacted the famous Judgement of Paris tasting, pitting France v USA. You set the budget and we can create a tasting allowing you to compare any wines you have ever wished to try.</p>
            </div>
            <div className='col-md-5'>
              <img className='featurette-image img-responsive mx-auto' data-src='/images/tasting.jpg/500x500/auto' alt='500x500' style={{ width: '500px', height: 'auto' }} src='/images/dinner4.jpg' data-holder-rendered='true' />
            </div>
          </div>

          <hr className='featurette-divider' />

          <div className='row featurette'>
            <div className='col-md-7 col-md-push-5'>
              <h2 className='featurette-heading' style={{ marginTop: '5rem' }}>Blind tasting quiz evenings.</h2>
              <p className='lead'>While tasting a range of new or familiar wines is enjoyable enough anyway, we can add an element of competition, which adds a little structure to your tasting. With a quiz that can be as light hearted or fiendishly difficult as your knowledge level allows, you and your guests will find out who ‘knows his Claret from his Beaujolais’. Unlike other wine merchants or clubs, we will bring this to your home.</p>
            </div>
            <div className='col-md-5 col-md-pull-7'>
              <img className='featurette-image img-responsive mx-auto' data-src='holder.js/500x500/auto' alt='500x500' style={{ width: '500px', height: 'auto' }} src='/images/quiz.jpg' data-holder-rendered='true' />
            </div>
          </div>

          <hr className='featurette-divider' />

          <div className='row featurette'>
            <div className='col-md-7'>
              <h2 className='featurette-heading' style={{ marginTop: 0 }}>Bespoke wine cases to match your dinner.</h2>
              <p className='lead'>What are the best wines to serve with that lovely dinner you’re preparing? A good match is always better than just bringing out something expensive because you have guests. We can help with that at all price levels; whether we include a Sparkling aperitif, through White, maybe Pink, then Red (or a different White) and Sweet wines if you choose. Tell us your plans and let Wheeler Wines put a package together into one case or two depending on numbers and thirst levels of your guests!</p>
            </div>
            <div className='col-md-5'>
              <img className='featurette-image img-responsive mx-auto' data-src='holder.js/500x500/auto' alt='500x500' style={{ width: '500px', height: 'auto', marginBottom: 15 }} src='/images/dinner2.jpg' data-holder-rendered='true' />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Events
