import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { inject, observer } from 'mobx-react'
import BuyPanel from './BuyPanel'
import Flag from './Flag'

class WineCard extends Component {
  handleClick = () => {
    this.props.history.push(`/wines/${this.props.wine.id}`)
  }

  showRibbon = (display, size) => {
    if (!display) {
      return null
    }

    switch (size) {
      case 150:
        return (
          <div className='ribbon blue'><span>MAGNUM</span></div>
        )
      case 37.5:
        return (
          <div className='ribbon blue'><span>HALF</span></div>
        )
      default:
        return null
    }
  }

  render () {
    const { wine, display } = this.props
    // Get qty in cart...
    let qty = 0
    const wineInCart = this.props.store.cart.find(item => item.id === wine.id)
    if (wineInCart) {
      qty = wineInCart.qty
    }

    let laydownImages = null
    if (wine.stars && wine.stars > 0) {
      let image = `/bottles/laydown-${wine.type}.gif`
      if (wine.type === 'sparkling') {
        image = '/bottles/laydown-white.gif'
      }
      // laydownImages = <img src={`/bottles/laydown-${wine.type}.gif`} alt='bottle' />
      laydownImages = Array(wine.stars + 1).join(0).split('').map((w, i) => <img className='mt-0 ml-3' key={i} src={image} alt='bottle' />)
    }

    const displayStyle = display ? {} : { display: 'none' }
    return (
      <div className='col col-12 col-md-6 col-lg-4' style={displayStyle}>
        {this.showRibbon(display, wine.size)}
        <div className='thumbnail' onClick={this.handleClick} style={{ cursor: 'pointer', backgroundColor: (wine.live ? 'white' : 'grey') }}>
          {display ? <img style={{ marginTop: 10, marginLeft: 'auto', marginRight: 'auto', display: 'block', width: 'auto', height: '170px' }} src={`/bottles/${wine.image}`} alt='bottle' /> : null}
          <div className='caption'>
            <h4 className='card-title' style={{ height: '48px' }}>{wine.name} {wine.vintage} <Flag countryCode={wine.country} /> {laydownImages}</h4>
            <p className='card-text' style={{ height: '65px' }}>{wine.header}</p>
            <p className='card-text' style={{ display: 'none' }}>{wine.description}</p>
            {display ? <BuyPanel wine={wine} token={this.props.store.token} user={this.props.store.user} numberInCart={qty} cart={this.props.store.cart} /> : null}
          </div>
        </div>
      </div>
    )
  }
}

WineCard.propTypes = {
  wine: PropTypes.object.isRequired,
  history: PropTypes.object,
  display: PropTypes.bool
}

export default inject('store')(observer(WineCard))
