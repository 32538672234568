import gravatar from 'gravatar'

export const fetchGravatarURL = (email) => {
  const httpsUrl = gravatar.url(email, {
    protocol: 'https',
    s: '64', // Size
    r: 'pg', // Rating, PG only please :)
    d: 'mm' // will return a default mystery-man image
  })

  return httpsUrl
}
