import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import WineRackSection from './WineRackSection'

function hashCode (str) {
  let hash = 0
  if (str.length === 0) {
    return hash
  }

  for (let i = 0; i < str.length; i++) {
    const character = str.charCodeAt(i)
    hash = ((hash << 5) - hash) + character
    hash &= hash // Convert to 32bit integer
  }
  return hash
}

class WineRack extends Component {
  render () {
    const bottles = []
    const strays = []

    this.props.cart.forEach(item => {
      // Calculate how many cases of 6 we have
      const numberOfCases = Math.floor(item.qty / 6)
      const remaining = item.qty % 6

      for (let i = 0; i < numberOfCases * 6; i++) {
        bottles.push(item)
      }

      for (let i = 0; i < remaining; i++) {
        strays.push(item)
      }
    })

    // Now add the strays to the end of the list of bottles
    strays.forEach(item => {
      bottles.push(item)
    })

    let numberOfSections = Math.floor(bottles.length / 6)
    if (bottles.length % 6 > 0) {
      numberOfSections += 1
    }
    if (numberOfSections < 2) {
      numberOfSections = 2
    }

    const racks = []

    for (let i = 0; i < numberOfSections; i++) {
      racks.push(bottles.slice(i * 6, (i + 1) * 6))
    }

    return (
      <div>
        <p>Minimum order of 12 bottles or £100.</p>
        <div>
          {racks.map((rack, i) => {
            const hash = hashCode(JSON.stringify(rack) + '|' + i)
            return (
              <WineRackSection key={hash} bottles={rack} />
            )
          })}
        </div>
      </div>
    )
  }
}

WineRack.propTypes = {
  cart: PropTypes.object.isRequired
}

export default WineRack
