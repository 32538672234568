import {
  decorate,
  observable,
  action,
  computed,
  autorun,
  runInAction,
  toJS
} from 'mobx'
import uuidV4 from 'uuid/v4'
import ConnectionManager from './ConnectionManager'
import { fetchGravatarURL } from './GravatarHelper'
import logger from './utils/logger'

const checkInMillis = 5000
const slowRTT = 500 // Not sure what this value should be!

class Store {
  constructor () {
    this.STATUS_GOOD = 'good'
    this.STATUS_WARN = 'warning'
    this.STATUS_BAD = 'bad'

    this.wines = []

    this.lastUserCode = null
    this.user = null
    this.token = null
    this.lastCheckInResponseMillis = null
    this.loginState = null
    this.resetState = null

    this.connectionStatus = {
      status: this.STATUS_WARN,
      messages: ['Waiting for initial server response']
    }

    this.roundTripTimes = []
    this.totalCheckInCount = 0
    this.totalRTTMillis = 0
    this.cart = []

    this.name = ''
    this.email = ''
    this.message = ''

    this.messageCounter = 0 // Not observable, used in charting, which isn't reactive (d3), convienient place to keep it so ConnectionManager and Heartbeat.js can access it

    if (typeof window === 'undefined') {
      global.window = {
        localStorage: {
          removeItem: () => { }
        }
      }
    }

    if (typeof document === 'undefined') {
      global.document = {}
    }

    if (window.localStorage.machineId) {
      this.machineId = window.localStorage.machineId
    } else {
      this.machineId = uuidV4()
      window.localStorage.machineId = this.machineId
    }

    if (window.localStorage.user) {
      this.user = JSON.parse(window.localStorage.user)
      if (window.localStorage.carts) {
        const carts = JSON.parse(window.localStorage.carts)
        if (carts[this.user.userCode]) {
          this.cart = carts[this.user.userCode]
        }
      } else {
        this.cart = []
      }
    }

    if (window.localStorage.token) {
      this.token = window.localStorage.token
    }

    if (window.localStorage.lastUserCode) {
      this.lastUserCode = window.localStorage.lastUserCode
    }

    if (this.wines.length === 0) {
      this.getWines()
    }

    window.store = this
  }

  saveWine (data, cb) {
    this.connectionManager.sendRequest({
      method: 'update',
      params: {
        data
      },
      cb: (err, message) => {
        cb(err, message)
      }
    })
  }

  duplicateWine (id, cb) {
    this.connectionManager.sendRequest({
      method: 'duplicate',
      params: {
        data: id
      },
      cb: (err, message) => {
        cb(err, message)
      }
    })
  }

  getWines () {
    // We only want to do this if the user has logged in or out since the last time we ran
    const loggedIn = this.user && this.token
    if (this.loggedInLastTime === loggedIn) {
      return
    }

    this.loggedInLastTime = loggedIn

    if (this.connectionManager) {
      this.connectionManager.closeSocket()
    }

    this.wines = []

    const wines = []
    let queryState = 'loading'

    this.connectionManager = ConnectionManager('/winelist', this)

    this.connectionManager.sendRequest({
      method: 'getList',
      keepAlive: true,
      cb: (err, message) => {
        if (err) {
          logger.error('ERROR', err)
        } else {
          switch (message.type) {
            case 'state':
              if (message.data === 'ready') {
                queryState = 'ready'
                this.wines = wines
              }
              break
            case 'data':
              switch (queryState) {
                case 'loading':
                  wines.push(message.data)
                  break
                case 'ready': {
                  const w = this.wines.filter((wine) => wine.id !== message.data.id)
                  w.push(message.data)
                  this.wines = w
                  break
                }
                default:
                  throw new Error('Unexpected state')
              }
              break
            case 'dataDeleted':
              this.wines = this.wines.filter((wine) => wine.id !== message.data)
              break
            default:
              throw new Error('Unexpected case ', message.type)
          }
        }
      }
    })
  }

  login ({ userCode, password }) {
    if (!userCode || !password) {
      this.loginState = {
        error: 'Please provide username and password',
        isLoggingIn: false
      }
    } else {
      const sendRequest = ConnectionManager('/users', this).sendRequest

      sendRequest({
        method: 'login',
        params: {
          data: {
            userCode,
            password
          }
        },
        cb: (err, response) => {
          if (err) {
            this.loginState = {
              error: err.data,
              isLoggingIn: false
            }
          } else {
            const {
              type,
              data
            } = response

            if (type === 'error') {
              this.loginState = {
                error: data,
                isLoggingIn: false
              }
            } else {
              runInAction('update state after login', () => {
                this.loginState = null
                this.user = data.user
                this.token = data.token
                this.lastUserCode = userCode
                if (window.localStorage.carts) {
                  const carts = JSON.parse(window.localStorage.carts)
                  if (carts[data.user.userCode]) {
                    this.cart = carts[data.user.userCode]
                  }
                } else {
                  this.cart = []
                }
              })
            }
          }
        }
      })
    }
  }

  logout (optionalErrorMessage = '') {
    runInAction('update state after logout', () => {
      this.user = null
      this.token = null
      this.cart = []
      if (optionalErrorMessage) {
        this.loginState = {
          error: optionalErrorMessage,
          isLoggingIn: false
        }
      }
    })
  }

  resetPassword ({ email }) {
    if (!email) {
      this.resetState = {
        error: 'Please provide your email address',
        isResetting: false,
        done: false
      }
    } else {
      this.resetState = {
        error: null,
        isResetting: true,
        done: false
      }
      const sendRequest = ConnectionManager('/users', this).sendRequest

      sendRequest({
        method: 'resetPassword',
        params: {
          data: {
            email
          }
        },
        cb: (err, response) => {
          if (err) {
            this.resetState = {
              error: err.data,
              isResetting: false,
              done: false
            }
          } else {
            const {
              type,
              data
            } = response

            if (type === 'error') {
              this.resetState = {
                error: data,
                isResetting: false,
                done: false
              }
            } else {
              this.resetState = {
                error: null,
                isResetting: false,
                done: true
              }
            }
          }
        }
      })
    }
  }

  startCheckingIn () {
    clearInterval(this.checkInTimer)

    this.checkInTimer = setInterval(() => {
      this.checkIn()
    }, checkInMillis)
  }

  checkIn () {
    const sendRequest = ConnectionManager('/heartbeat', this).sendRequest

    sendRequest({
      method: 'checkIn',
      params: {
        data: {
          userCode: (this.user && this.user.userCode ? this.user.userCode : ''),
          clientMillis: new Date().getTime(),
          machineId: this.machineId
        }
      },
      cb: (err, response) => {
        if (err || (response && response.type === 'error')) {
          return logger.error(err || (response && response.data))
        }

        const {
          data
        } = response

        let status = this.STATUS_GOOD

        if (data.serverStatus === this.STATUS_BAD) {
          status = this.STATUS_BAD
        }

        this.lastCheckInResponseMillis = new Date().getTime()
        const rtt = this.lastCheckInResponseMillis - data.clientData.clientMillis
        this.roundTripTimes.unshift(rtt)
        this.roundTripTimes = this.roundTripTimes.slice(0, 10)
        this.totalCheckInCount = this.totalCheckInCount + 1
        this.totalRTTMillis = this.totalRTTMillis + rtt

        const messages = data.messages

        if (rtt > slowRTT) {
          messages.push(`Last RTT was ${rtt}ms`)
          status = this.STATUS_WARN
        }

        this.setConnectionStatus({ status, messages })
      }
    })
  }

  get averageRTT () {
    if (this.totalCheckInCount > 0) {
      return Math.round(this.totalRTTMillis / this.totalCheckInCount)
    }
    return 0
  }

  setConnectionStatus ({ status = this.connectionStatus.status, messages = this.connectionStatus.messages }) {
    // Note: Don't overwrite connectionStatus with new object, this causes mobx to re render as it is looking at the object not values within it
    this.connectionStatus.status = [this.STATUS_GOOD, this.STATUS_WARN, this.STATUS_BAD].includes(status) ? status : this.STATUS_BAD

    // Only replace messages if they have changed to prevent re render
    messages = messages.filter((message) => {
      return !this.connectionStatus.messages.includes(message)
    })

    if (messages.length > 0) {
      this.connectionStatus.messages = messages
    }
  }
}

decorate(Store, {
  wines: observable,
  lastUserCode: observable,
  user: observable,
  token: observable,
  lastCheckInResponseMillis: observable,
  loginState: observable,
  resetState: observable,
  connectionStatus: observable,
  roundTripTimes: observable,
  totalCheckInCount: observable,
  totalRTTMillis: observable,
  cart: observable,
  name: observable,
  email: observable,
  message: observable,
  saveWine: action,
  duplicateWine: action,
  getWines: action,
  login: action,
  logout: action,
  averageRTT: computed,
  setConnectionStatus: action
})

const store = new Store()
export default store

// Make sure atuorun goes after the store is constructed
// If the autorun is going to be modifying state then it should not go in the
// constructor as the store may not have been constructed when reactions begin occuring
// e.g. Setting the user from the localstorage
autorun(() => {
  if (store.lastUserCode) {
    window.localStorage.lastUserCode = store.lastUserCode
  }

  if (store.user && store.token) {
    const userJSON = JSON.stringify(store.user)
    window.localStorage.user = userJSON
    window.localStorage.token = store.token
    document.cookie = `user=${userJSON};path='/';max-age=864000`
    document.cookie = `token=${store.token};path='/';max-age=864000`

    try {
      const pictureURL = fetchGravatarURL(store.user.email)
      store.user.pictureURL = pictureURL
    } catch (err) {
      logger.error(err)
    }
  } else {
    window.localStorage.removeItem('user')
    window.localStorage.removeItem('token')
    document.cookie = 'user=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    clearInterval(store.checkInTimer)
  }

  store.getWines()

  store.startCheckingIn()

  // Persist the cart to locateStorage
  if (store.user) {
    const carts = JSON.parse(window.localStorage.carts || '{}')
    const cart = toJS(store.cart)
    const user = toJS(store.user.userCode)
    if (cart.length === 0) {
      delete carts[user]
    } else {
      carts[user] = cart
    }
    window.localStorage.carts = JSON.stringify(carts)
  }
})
