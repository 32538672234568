import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import logger from '../utils/logger'
import HistoryRow from './HistoryRow'

import ConnectionManager from '../ConnectionManager'

class History extends Component {
  constructor (props) {
    super(props)

    this.state = {
      queryState: 'loading',
      history: [],
      filterText: ''
    }

    this.history = []
  }

  componentWillUnmount () {
    if (this.connectionManager) {
      this.connectionManager.closeSocket()
    }
  }

  componentDidMount () {
    this.connectionManager = ConnectionManager('/users', this.props.store)

    this.connectionManager.sendRequest({
      method: 'history',
      keepAlive: true,
      cb: (err, message) => {
        if (err) {
          logger.error('ERROR', err)
        } else {
          switch (message.type) {
            case 'state':
              if (message.data === 'ready') {
                this.setState({
                  queryState: 'ready',
                  history: this.history
                })
                this.history = null
              }
              break
            case 'data':
              switch (this.state.queryState) {
                case 'loading':
                  this.history.push(message.data)
                  break
                case 'ready': {
                  const h = this.state.history.filter((history) => history.id !== message.data.id)
                  h.push(message.data)
                  this.setState({
                    history: h
                  })
                  break
                }
                default:
                  throw new Error('Unexpected state')
              }
              break
            case 'dataDeleted':
              this.setState({
                history: this.state.history.filter((history) => history.id !== message.data)
              })
              break
            default:
              throw new Error('Unexpected case ', message.type)
          }
        }
      }
    })
  }

  handleFilterChange = (e) => {
    this.setState({
      filterText: e.target.value
    })
  }

  prettyDate = (date) => {
    const parts = new Date(date).toDateString().match(/(.{3}) (.+) (\d{2}) (\d{4}).*/)
    return `${parts[1]} ${parts[3]} ${parts[2]} ${parts[4]}`
  }

  isMatch = (item) => {
    if (this.state.filterText.trim().length === 0) {
      return true
    }
    let text = this.prettyDate(item.date) + '|' + item.id + '|'
    item.cart.forEach(i => {
      text += i.name + ' ' + i.vintage + '|' + i.price
    })

    return text.toUpperCase().match(this.state.filterText.trim().toUpperCase())
  }

  render () {
    return (
      <div className='container' style={{ marginTop: 40 }}>
        <div className='row'>
          <form className='form-inline'>
            <input className='form-control' style={{ minWidth: 400 }} type='text' placeholder='Search for orders containing dates, wines, prices...' value={this.state.filterText} onChange={this.handleFilterChange} title='Search by wine, date, amount' />
          </form>
        </div>

        {this.state.history.filter(item => this.isMatch(item)).sort((a, b) => new Date(a.date).getTime() < new Date(b.date).getTime()).map(history => {
          const date = this.prettyDate(history.date)

          return (
            <div className='row' key={history.id}>
              <table className='table' style={{ marginTop: 20 }}>
                <thead className='thead-primary'>
                  <tr>
                    <th>{date} <span style={{ marginLeft: 10, fontSize: 'x-small' }}>{`Order ${history.id}`}</span></th>
                    <th className='text-right'>Qty</th>
                    <th className='text-right'>Price</th>
                    <th className='text-right'>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {history.cart.map(item => {
                    return (
                      <HistoryRow
                        key={history.id + '|' + item.id}
                        name={item.name}
                        qty={item.qty}
                        vintage={'' + item.vintage}
                        price={item.price}
                      />
                    )
                  })}
                </tbody>
              </table>
            </div>
          )
        })}
      </div>
    )
  }
}

export default inject('store')(observer(History))
