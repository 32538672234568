import React from 'react'
import { Route, Switch } from 'react-router'

import Login from './components/Login'
import ResetPassword from './components/ResetPassword'
import WineList from './components/WineList'
import Events from './components/Events'
import Users from './admin/Users'
import Orders from './admin/Orders'
import Contact from './components/Contact'
import Confirm from './components/ConfirmForm'
import Home from './components/Home'
import Cart from './components/Cart'
import History from './components/History'
import Logout from './components/Logout'
import Cellar from './components/Cellar'

export default function Routes (user, token) {
  return (
    <Switch>
      <Route exact path='/confirm/:token' component={Confirm} />
      <Route exact path='/' component={Home} />
      <Route path='/wines/:wineid/edit' component={WineList} />
      <Route path='/wines/:wineid' component={WineList} />
      <Route path='/wines' component={WineList} />
      <Route path='/history' component={History} />
      <Route path='/contact' component={Contact} />
      <Route path='/cellar' component={Cellar} />
      <Route path='/events' component={Events} />
      <Route path='/cart' component={Cart} />
      <Route path='/login' component={Login} />
      <Route path='/logout' component={Logout} />
      <Route path='/resetpassword' component={ResetPassword} />
      <Route
        path='/admin/users' render={() => {
          return user && user.administrator === true
            ? <Users />
            : <div>NOT AUTHORISED</div>
        }}
      />
      <Route
        path='/admin/orders' render={() => {
          return user && user.administrator === true
            ? <Orders />
            : <div>NOT AUTHORISED</div>
        }}
      />
      <Route path='*' render={() => <div>Not found</div>} />
    </Switch>
  )
}
