module.exports = function formatPrice (currency, value) {
  const n = Number(Math.round(value + 'e2') + 'e-2').toFixed(2)

  const withCommas = n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  switch (currency) {
    case 'GBP':
      return '£' + withCommas
    default:
      return currency + ' ' + withCommas
  }
}
