import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { inject, observer } from 'mobx-react'
import BuyPanel from './BuyPanel'
import Flag from './Flag'
import logger from '../utils/logger'

function createMarkup (text) {
  return {
    __html: text.replace(/<script/ig, '&lt;script')
  }
}

class WineDetail extends Component {
  handleBack = () => {
    this.props.history.goBack()
  }

  handleEdit = () => {
    this.props.history.push(`/wines/${this.props.wine.id}/edit`)
  }

  handleDuplicate = () => {
    this.props.store.duplicateWine(this.props.wine.id, (err, message) => {
      if (err) {
        logger.error(err.data)
        this.props.history.goBack()
      } else {
        this.props.history.replace(`/wines/${message.data}/edit`)
      }
    })
  }

  render () {
    const { wine } = this.props
    if (!wine) {
      return (
        <div>Not found</div>
      )
    }

    const { user } = this.props.store

    // Get qty in cart...
    let qty = 0
    const wineInCart = this.props.store.cart.find(item => item.id === wine.id)
    if (wineInCart) {
      qty = wineInCart.qty
    }

    let laydownImages = null
    if (wine.stars && wine.stars > 0) {
      let image = `/bottles/laydown-${wine.type}.gif`
      if (wine.type === 'sparkling') {
        image = '/bottles/laydown-white.gif'
      }
      // laydownImages = <img src={`/bottles/laydown-${wine.type}.gif`} alt='bottle' />
      laydownImages = Array(wine.stars + 1).join(0).split('').map((w, i) => <img className='mt-0 ml-3' key={i} src={image} alt='bottle' />)
    }

    const style = this.props.showSpotlight
      ? {
          backgroundColor: (wine.live ? 'transparent' : 'grey'),
          backgroundSize: '180px 180px',
          backgroundImage: 'url(/images/spotlight2.png)',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '5px 0px',
          backgroundBlendMode: 'overlay',
          cursor: 'pointer'
        }
      : {}

    return (
      <div className='container' style={{ marginTop: 10 }}>
        {this.props.showSpotlight
          ? null
          : (
            <div className='row' style={{ marginBottom: 20 }}>
              <button className='btn btn-primary' onClick={this.handleBack} style={{ marginLeft: 10, marginRight: 10 }}>Back</button>
              {user && user.administrator
                ? (
                  <button className='btn btn-default' style={{ marginRight: 10 }} onClick={this.handleEdit}>Edit</button>
                  )
                : null}
              {user && user.administrator
                ? (
                  <button className='btn btn-default' onClick={this.handleDuplicate}>Duplicate</button>
                  )
                : null}
            </div>)}
        {this.props.showSpotlight ? <h3 style={{ textAlign: 'center' }}>In the Spotlight</h3> : null}
        <div className='visible-xs col col-12 col-lg-4' style={{ marginTop: 20, paddingLeft: 0, paddingRight: 0 }}>
          <div className='thumbnail' style={{ cursor: 'pointer' }}>
            <img style={{ marginTop: 10, marginLeft: 'auto', marginRight: 'auto', display: 'block', width: 'auto', height: '170px' }} src={`/bottles/${wine.image}`} alt='bottle' />
            <div className='caption'>
              <h4 className='card-title' style={{ height: '48px' }}>{wine.name} {wine.vintage} <Flag countryCode={wine.country} /> {laydownImages}</h4>
              <p className='card-text' style={{ height: '65px' }}>{wine.header}</p>
              <p className='card-text' dangerouslySetInnerHTML={createMarkup(wine.description.replace(/<CR>/g, '<br />'))} />
              <BuyPanel wine={wine} token={this.props.store.token} user={this.props.store.user} numberInCart={qty} cart={this.props.store.cart} />
            </div>
          </div>
        </div>
        <div className='hidden-xs caption'>
          <div style={{ display: 'table' }}>
            <div style={{ display: 'table-column' }} />
            <div style={{ display: 'table-column' }} />
            <div style={style}>
              <div style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center', minWidth: 200 }}>
                <img style={{ width: 'auto', height: 190 }} src={`/bottles/${wine.image}`} alt='bottle' />
              </div>
              <div style={{ display: 'table-cell' }}>
                <h4 style={{ height: '48px' }}>{wine.name} {wine.vintage} <Flag countryCode={wine.country} /> {laydownImages}</h4>
                <p>{wine.header}</p>
                <p style={{ marginBottom: 15 }} dangerouslySetInnerHTML={createMarkup(wine.description.replace(/<CR>/g, '<br />'))} />
                <BuyPanel wine={wine} token={this.props.store.token} user={this.props.store.user} numberInCart={qty} cart={this.props.store.cart} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

WineDetail.propTypes = {
  wine: PropTypes.object.isRequired,
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
  showSpotlight: PropTypes.bool.isRequired
}

export default inject('store')(observer(WineDetail))
