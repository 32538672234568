import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { inject, observer } from 'mobx-react'
import logger from '../utils/logger'
import OrderRow from './OrderRow'
import Spinner from '../components/common/Spinner'
import formatPrice from '../utils/formatPrice'

import ConnectionManager from '../ConnectionManager'

class Orders extends Component {
  constructor (props) {
    super(props)

    this.state = {
      userQueryState: 'loading',
      orderQueryState: 'loading',
      users: [],
      orders: [],
      filterText: ''
    }

    this.users = []
    this.orders = []
  }

  componentWillUnmount () {
    if (this.userConnectionManager) {
      this.userConnectionManager.closeSocket()
    }
    if (this.orderConnectionManager) {
      this.orderConnectionManager.closeSocket()
    }
  }

  componentDidMount () {
    this.userConnectionManager = ConnectionManager('/users', this.props.store)
    this.orderConnectionManager = ConnectionManager('/orders', this.props.store)

    this.userConnectionManager.sendRequest({
      method: 'all',
      keepAlive: true,
      cb: (err, message) => {
        if (err) {
          logger.error('ERROR', err)
        } else {
          switch (message.type) {
            case 'state':
              if (message.data === 'ready') {
                this.setState({
                  userQueryState: 'ready',
                  users: this.users
                })
                this.users = null
              }
              break
            case 'data':
              switch (this.state.userQueryState) {
                case 'loading':
                  this.users.push(message.data)
                  break
                case 'ready': {
                  const u = this.state.users.filter((user) => user.userCode !== message.data.userCode)
                  u.push(message.data)
                  this.setState({
                    users: u
                  })
                  break
                }
                default:
                  throw new Error('Unexpected state')
              }
              break
            case 'dataDeleted':
              this.setState({
                users: this.state.users.filter((user) => user.userCode !== message.data)
              })
              break
            default:
              throw new Error('Unexpected case ', message.type)
          }
        }
      }
    })

    this.orderConnectionManager.sendRequest({
      method: 'getOrders',
      keepAlive: true,
      cb: (err, message) => {
        if (err) {
          logger.error('ERROR', err)
        } else {
          switch (message.type) {
            case 'state':
              if (message.data === 'ready') {
                this.setState({
                  orderQueryState: 'ready',
                  orders: this.orders
                })
                this.orders = null
              }
              break
            case 'data':
              switch (this.state.orderQueryState) {
                case 'loading':
                  this.orders.push(message.data)
                  break
                case 'ready': {
                  const o = this.state.orders.filter((order) => order.id !== message.data.id)
                  o.push(message.data)
                  this.setState({
                    orders: o
                  })
                  break
                }
                default:
                  throw new Error('Unexpected state')
              }
              break
            case 'dataDeleted':
              this.setState({
                orders: this.state.orders.filter((order) => order.id !== message.data)
              })
              break
            default:
              throw new Error('Unexpected case ', message.type)
          }
        }
      }
    })
  }

  handleSearchChange = (e) => {
    this.setState({
      filterText: e.target.value
    })
  }

  prettyDate (date) {
    if (typeof date === 'string' || typeof date === 'number') {
      date = new Date(date)
    }
    const day = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()]
    const dd = ('00' + date.getDate()).slice(-2)
    const MM = ('00' + (date.getMonth() + 1)).slice(-2)
    const yyyy = date.getFullYear()
    const hh = ('00' + date.getHours()).slice(-2)
    const mm = ('00' + date.getMinutes()).slice(-2)
    return `${day} ${yyyy}-${MM}-${dd} ${hh}:${mm}`
  }

  isMatch (item) {
    if (this.state.filterText.trim().length === 0) {
      return true
    }
    const user = this.state.users.filter(user => user.userCode === item.userCode)[0]

    let text = this.prettyDate(item.date) + '|' + item.id + '|'
    text += item.userCode + '|'
    if (user) {
      if (user.firstName) {
        text += user.firstName + '|'
      }
      if (user.lastName) {
        text += user.lastName + '|'
      }
    }
    item.cart.forEach(i => {
      text += i.name + ' ' + i.vintage + '|' + i.price
    })

    return text.toUpperCase().match(this.state.filterText.trim().toUpperCase())
  }

  updateDelivered (orderId, itemId, qtyDelivered) {
    this.connectionManager = ConnectionManager('/orders', this.props.store)

    this.connectionManager.sendRequest({
      method: 'updateQtyDelivered',
      params: {
        data: {
          orderId,
          itemId,
          qtyDelivered
        }
      },
      cb: (err, message) => {
        if (err) {
          logger.error('ERROR', err)
          this.setState({
            queryState: 'error'
          })
        }
      }
    })
  }

  markAsPaid (orderId, e) {
    this.connectionManager = ConnectionManager('/orders', this.props.store)

    this.connectionManager.sendRequest({
      method: 'markAsPaid',
      params: {
        data: {
          orderId,
          paid: e.target.checked
        }
      },
      cb: (err, message) => {
        if (err) {
          logger.error('ERROR', err)
          this.setState({
            queryState: 'error'
          })
        }
      }
    })
  }

  getPaymentInfo (order) {
    if (order.stripe_data && order.stripe_data.paid) {
      return `Paid ${formatPrice('GBP', order.stripe_data.amount / 100)} through Stripe (${order.stripe_data.id})`
    }

    return (
      <label><input type='checkbox' value={order.paid || false} checked={order.paid || false} onChange={this.markAsPaid.bind(this, order.id)} />{` Paid ${order.paid ? '(' + this.prettyDate(order.paid) + ')' : ''}`}</label>
    )
  }

  render () {
    if (this.state.userQueryState === 'loading' || this.state.orderQueryState === 'loading') {
      return <Spinner text='Loading...' className='spaceAfter ' />
    }

    return (
      <div className='container' style={{ marginTop: 40 }}>
        <div className='row'>
          <form className='form-inline'>
            <input className='form-control' style={{ minWidth: 400 }} type='text' placeholder='Search for orders containing dates, wines, prices...' value={this.state.filterText} onChange={this.handleSearchChange} title='Search by wine, date, amount' />
          </form>
        </div>

        {this.state.orders.filter(item => this.isMatch(item)).sort((a, b) => Number(new Date(b.date)) - Number(new Date(a.date))).map(order => {
          const date = this.prettyDate(order.date)
          const user = this.state.users.filter(user => user.userCode === order.userCode)[0]
          let firstName
          let lastName
          let email

          if (user) {
            firstName = user.firstName
            lastName = user.lastName
            email = user.email
          } else {
            firstName = 'UNKNOWN'
            lastName = 'UNKNOWN'
            email = order.userCode
          }

          return (
            <div key={order.id} className='row'>
              <hr style={{ height: 5, color: 'silver', backgroundColor: 'silver' }} />
              <table className='table' style={{ marginTop: 20 }}>
                <thead className='thead-primary'>
                  <tr>
                    <th className='grid' style={{ width: 300 }}>
                      <div>{date}</div>
                      <div style={{ minWidth: 260, fontSize: 'x-small' }}>{`Order ${order.id}`}</div>
                      <div>{`${firstName} ${lastName} (${email})`}</div>
                    </th>
                    <th className='text-right'>Qty</th>
                    <th className='text-right'>Delivered</th>
                    <th className='text-right'>Price</th>
                    <th className='text-right'>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {order.cart.sort((a, b) => (a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0).map(item => {
                    return (
                      <OrderRow
                        key={order.id + '|' + item.id}
                        item={item}
                        updateDelivered={this.updateDelivered.bind(this, order.id, item.id)}
                      />
                    )
                  })}
                  <tr>
                    <td style={{ verticalAlign: 'middle', minWidth: 100, fontWeight: 'bold' }}>{this.getPaymentInfo(order)}</td>
                    <td />
                    <td />
                    <td />
                    <td className='text-right' style={{ verticalAlign: 'middle', minWidth: 100, fontWeight: 'bold' }}>
                      {formatPrice('GBP', order.cart.reduce((total, item) => (total += (item.qty * item.price)), 0))}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ marginLeft: 10 }}><i>{order.comments}</i></div>
            </div>
          )
        })}
      </div>
    )
  }
}

Orders.propTypes = {
  store: PropTypes.object
}

export default inject('store')(observer(Orders))
