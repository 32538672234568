import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import formatPrice from '../utils/formatPrice'

class WineRack extends Component {
  colors (wine) {
    switch (wine) {
      case 'sparkling':
        return ['rgb(39, 73, 22)', 'white']
      case 'white':
        return ['rgb(253, 251, 225)', 'black']
      case 'rose':
        return ['rgb(245, 184, 153)', 'black']
      case 'red':
        return ['rgb(45, 0, 1)', 'white']
      default:
        return ['white', 'black']
    }
  }

  render () {
    return (
      <svg className='m-2' width={144} height={96} viewBox='0 0 180 120' style={{ marginRight: 10, marginBottom: 10 }}>
        <g>
          <rect
            width={180}
            height={120}
            x={0}
            y={0}
            style={{ fill: 'none', stroke: 'silver', strokeWidth: 2 }}
          />
          <line x1={0} y1={60} x2={180} y2={60} style={{ fill: 'none', stroke: 'silver' }} />
          <line x1={60} y1={0} x2={60} y2={120} style={{ fill: 'none', stroke: 'silver' }} />
          <line x1={120} y1={0} x2={120} y2={120} style={{ fill: 'none', stroke: 'silver' }} />
        </g>
        {this.props.bottles.map((bottle, i) => {
          const x = (i % 3) * 60
          const y = Math.floor(i / 3) * 60
          const colors = this.colors(bottle.type)
          return (
            <g key={i}>
              <circle cx={x + 30} cy={y + 30} r={26} style={{ fill: colors[0], stroke: 'silver' }} />
              <text x={x + 30} y={y + 30} style={{ fill: colors[1], textAnchor: 'middle', alignmentBaseline: 'central' }}>{formatPrice('GBP', bottle.price)}</text>
              <rect x={x} y={y} width={60} height={60} style={{ fill: 'transparent' }}>
                <title>{`${bottle.name}\n${formatPrice('GBP', bottle.price)}`}</title>
              </rect>
            </g>
          )
        })}
      </svg>
    )
  }
}

WineRack.propTypes = {
  bottles: PropTypes.array.isRequired
}

export default WineRack
